import { ref, UnwrapRef, watch } from "vue"
import { useThrottleWatch } from "./useThrottleWatch"

/**
 * Creates inner state which is synced with prop value
 * When value is changed few times per tick, it will emit only once, but value always actual.
 * 
 * It prevents issues when you need to update prop multiple times per tick, but you don't want wait
 * for the next tick when prop value is updated.
 */
export const useThrottleSyncProp = <Key extends string, Props extends Record<Key, any>>(
  key: Key,
  props: Props,
  emit: (event: any, ...args: any[]) => void,
) => {
  const items = ref(props[key])

  let doNotWatch = false

  watch(() => props[key], (v) => {
    doNotWatch = true
    items.value = v as UnwrapRef<Props[Key]>
  })

  useThrottleWatch(items, (v) => {
    if (doNotWatch) return (doNotWatch = false)
    emit('update:' + key, v)
  })

  return items
}

/**
 * Creates inner state which is synced with prop value
 * When value is changed few times per tick, it will emit only once, but value always actual
 * 
 * It prevents issues when you need to update prop multiple times per tick, but you don't want wait
 * for the next tick when prop value is updated.
 */
export const useThrottleModelValue = <Props extends Record<'value', any>>(
  props: Props,
  emit: (event: 'input', ...args: any[]) => void,
) => {
  const items = ref(props.value)

  let doNotWatch = false

  watch(() => props.value, (v) => {
    doNotWatch = true
    items.value = v as UnwrapRef<Props['value']>
  })

  useThrottleWatch(items, (v) => {
    if (doNotWatch) return (doNotWatch = false)
    emit('input', v)
  })

  return items
}