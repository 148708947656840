// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Parser } from 'json2csv';

const slugify = (str: string) => {
	return (str + '')
		.replace(/ /g, '-')
		.replace(/[^a-zA-Z0-9]/g, '-')
		.replace(/--*/g, '-')
		.toLowerCase();
}

export const useExportToCSV = (
	fields: string[],
) => {
	return {
		exportToCSV: (data: any[], filename: string) => {
            const opts = { fields };
            try {
                const parser = new Parser(opts);
                const csv = parser.parse(data);
                const fileURL = window.URL.createObjectURL(new Blob([csv]));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', slugify(filename) + ".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
            } catch (err) {
                console.error(err);
            }
		}
	}
}