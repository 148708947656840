
import { computed } from 'vue';

export default {
  props: {
      color: { type: String, default: 'q_green_1' },
      // TODO: Make it auto-detect the color
      textColor: { type: String, default: 'black' }
  },

  setup(props) {
      return {
          attrsComputed: computed(() => {
              if (props.color.startsWith('#')) {
                  return { style: `background-color: ${props.color}; color: ${props.textColor}` };
              } else {
                  return { class: props.color, style: `color: ${props.textColor}` };
              }
          })
      }
  }
};
