<template>
	<v-data-table
		v-bind="$attrs"
		v-on="computedListeners"
		class="q-expand-table"
		:class="{
			'q-expand-table--mobile': $vuetify.breakpoint.smAndDown,
			'q-expand-table--xs': $vuetify.breakpoint.xs,
            'q-expand-table--striped': $props.striped,
		}"
		:color="computedColor"
        :headers="computedHeaders"
		:items="items"
		:mobile-breakpoint="0"
		:single-expand="singleExpand"
		:expanded.sync="expanded"
		:footer-props="{
			...footerProps,
			itemsPerPageText: $vuetify.breakpoint.xs ? '' : 'Rows per page',
		}"
        @click:row="onRowClick"
	>
		<!-- Proxy all slots -->
		<template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotScope">
			<slot :name="name" v-bind="slotScope" />
		</template>
		<!-- Show expand button -->
		<template #[`item.q-expand-icon`]="{ item }">
			<div class="d-flex justify-end">
				<slot name="before-expand" v-bind="{ item }" />
				<v-icon>fas fa-chevron-down</v-icon>
				<slot name="open-row-button" v-bind="{ item }">
					<v-icon
						v-if="doShowOpenRowButton"
						class="ml-2"
						@click="$emit('click:row', item)"
						>fas fa-external-link-alt</v-icon
					>
				</slot>
				<slot name="after-expand" v-bind="{ item }" />
			</div>
		</template>
		<!-- Table inside exapnded item -->
		<template #expanded-item="slotScope" v-if="doShowExpand">
			<td
				class="q-expand-table__expand-td pa-0"
				:colspan="computedHeaders.length"
			>
				<div
					class="q-expand-table__expand-background"
					:style="`background: ${computedColor};`"
				/>
				<slot name="expand" v-bind="slotScope">
					<v-simple-table>
						<tbody>
							<tr
								v-for="header in headersInExpand"
								:key="header[headerKey]"
							>
								<td class="text-start">
									{{ header.text }}
								</td>
								<td class="text-end">
									<slot
										:name="`item.${header.value}`"
										v-bind="slotScope"
									>
										{{ slotScope.item[header.value] }}
									</slot>
								</td>
							</tr>
						</tbody>
					</v-simple-table>
					<slot name="expanded-item" v-bind="slotScope"></slot>
					<slot name="action" v-bind="slotScope"></slot>
				</slot>
			</td>
		</template>
	</v-data-table>
</template>
<script>
const expandIconHeader = {
	text: "",
	value: "q-expand-icon",
	sortable: false,
};

export default {
	props: {
		items: { type: Array, default: () => [] },
		visibleHeaders: { type: Array, default: () => [] },
		headers: { type: Array, default: () => [] },

		/** If true, visible headers are auto generated. It slices headers property according to breakpoint values. */
		auto: { type: Boolean, default: false },
		singleExpand: { type: Boolean, default: true },
		// Headers count. Works while auto is true.
		xs: { type: [Number, String], default: 1 },
		sm: { type: [Number, String], default: 2 },
		md: { type: [Number, String], default: 6 },
		lg: { type: [Number, String], default: 8 },

		/** Key used to filter headers that no should be in expand */
		headerKey: { type: String, default: "value" },
		/** Option to force show expand */
		showExpand: { type: Boolean, default: undefined },

		// Styles
		color: { type: String, default: "primary" },
		footerProps: { default: () => {} },
        striped: { type: Boolean, default: false },
	},

	data() {
		return { expanded: [] };
	},

	computed: {
		computedVisibleHeaders() {
			if (!this.$props.auto) {
				return this.transformVisibleHeaders(this.visibleHeaders);
			}

			const breakpoints = ["xs", "sm", "md", "lg"];

			let headers = this.headers;

			for (let i = 0; i < breakpoints.length; i++) {
				if (this.$vuetify.breakpoint[breakpoints[i]]) {
					headers = this.headers.slice(
						0,
						this.$props[breakpoints[i]]
					);
				}
			}

			return this.transformVisibleHeaders(headers);
		},

		headersInExpand() {
			if (this.visibleHeaders.length === 0 && !this.$props.auto) {
				return [];
			}

			const visibleHeaders = this.computedVisibleHeaders;

			return this.headers.filter(
				(header) =>
					!visibleHeaders.find((h) => header[this.headerKey] === h)
			);
		},

		doShowExpand() {
			if (
				this.$scopedSlots.expand ||
				this.$scopedSlots["expanded-item"] ||
				this.$scopedSlots.actions
			) {
				return true;
			}

			if (this.showExpand !== undefined) {
				return this.showExpand;
			}

			if (this.headersInExpand.length === 0) {
				return false;
			}

			return this.computedVisibleHeaders.length < this.headers.length;
		},

		doShowOpenRowButton() {
			return this.doShowExpand && this.$listeners["click:row"];
		},

		computedHeaders() {
			const filteredHeaders = this.headers.filter(
				(header) =>
					!this.headersInExpand.find(
						(h) => header[this.headerKey] === h[this.headerKey]
					)
			);

			if (this.doShowExpand) {
				filteredHeaders.push(expandIconHeader);
			}

			return filteredHeaders;
		},

		computedColor() {
			const currentTheme = this.$vuetify.theme.themes.light;

			return currentTheme[this.color] || this.color;
		},

		computedListeners() {
			if (this.doShowExpand) {
				const cp = { ...this.$listeners };

				delete cp["click:row"];

				return cp;
			}

			return this.$listeners;
		},
	},

	methods: {
		onRowClick(event, item) {
			item.expand(!item.isExpanded);
		},

		transformVisibleHeaders(arr) {
			return arr.map((h) =>
				typeof h === "object" ? h[this.headerKey] : h
			);
		},
	},
};
</script>
<style lang="scss">
.q-expand-table {
    width: 100%;

	&--mobile {
		// Mobile friendly text
		td {
			white-space: normal !important;
		}
	}

	&--xs {
		.v-data-footer__pagination {
			margin-left: 0;
			margin-right: 0;
		}
	}

    &--striped {
        tr:nth-child(odd) {
            td {
                background: rgba(0, 0, 0, 0.02) !important;
            }
        }
    }

	&__expand-td {
		position: relative;
		z-index: 0;
	}

	&__expand-background {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		opacity: 0.1;
	}

	.v-data-table {
		background: transparent;

		tr:hover {
			background: rgba(0, 0, 0, 0.02) !important;
		}

		.v-data-table {
			background: rgba(255, 255, 255, 0.3);
		}
	}
}
</style>
