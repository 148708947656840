var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.level == 0)?_c('v-list-group',{ref:"hoverEl",staticClass:"q-draggable-list-item",attrs:{"color":_vm.color},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{key:_vm.item[_vm.titleKey],staticClass:"d-flex"},[_c('v-icon',{staticClass:"pr-3",attrs:{"color":"grey lighten-2"}},[_vm._v(" fas fa-arrows-alt ")]),_vm._t("default",function(){return [_vm._v(_vm._s(_vm.item[_vm.titleKey]))]},null,{ item: _vm.item, level: _vm.level })],2)]},proxy:true}],null,true),model:{value:(_vm.expandedComputed),callback:function ($$v) {_vm.expandedComputed=$$v},expression:"expandedComputed"}},[(_vm.children.length > 0)?_c('VueDraggable',{staticClass:"q-draggable-list-item__children",class:{
				'q-draggable-list-item__children--dragging': _vm.isDragging,
				'q-draggable-list-item__children--disabled': _vm.disabled,
			},style:({
				paddingBottom: _vm.footerHeight + 'px',
			}),attrs:{"tag":"ul","value":_vm.children,"options":{
				group: { name: _vm.computedGroupId, put: () => true, },
			},"move":_vm.onMove},on:{"start":function($event){_vm.draggroup = _vm.computedGroupId},"end":function($event){_vm.draggroup = null},"input":_vm.updateChildren}},_vm._l((_vm.children),function(item){return _c('QDraggableListItem',_vm._g({key:item[_vm.titleKey],attrs:{"item":item,"level":_vm.level + 1,"group-id":_vm.computedGroupId,"color":_vm.color},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots)),function(slotName){return {key:`${slotName}`,fn:function(bind){return [_vm._t(slotName,null,null,bind)]}}})],null,true)},_vm.$listeners),[_vm._t("default",null,null,{ item, level: _vm.level })],2)}),1):_c('div',{staticClass:"q-draggable-list-item__children",style:({
			paddingBottom: _vm.footerHeight + 'px',
		})}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandedComputed),expression:"expandedComputed"}],ref:"footer",staticClass:"q-draggable-list-item__footer",class:{
			'q-draggable-list-item__footer--disabled': _vm.disabled,
		}},[_vm._t("item-footer",null,null,{ item: _vm.item, level: _vm.level })],2)],1):_c('v-list-item',{staticClass:"q-draggable-list-item--nested"},[_c('v-list-item-title',{key:_vm.item[_vm.titleKey],staticClass:"d-flex"},[_c('v-icon',{staticClass:"pr-3",attrs:{"color":"grey lighten-2"}},[_vm._v(" fas fa-arrows-alt ")]),_vm._t("default",function(){return [_vm._v(_vm._s(_vm.item[_vm.titleKey]))]},null,{ item: _vm.item, level: _vm.level })],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }