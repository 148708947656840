import { getCurrentInstance } from "vue"
import type VueRouter from "vue-router"
import { type Route } from "vue-router"

export const useRouter = () => {
  const vm = getCurrentInstance()

  return vm?.proxy.$router as VueRouter
}

export const useRoute = () => {
  const vm = getCurrentInstance()

  return vm?.proxy.$route as Route
}