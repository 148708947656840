import { useResizeObserver } from './useResizeObserver';
import { Ref, ref, watch } from "vue"

export const useElementHeight = (element: Ref<HTMLElement | null>) => {
  const height = ref(0)

  useResizeObserver(element, (entries) => {
    if (entries[0]) {
      height.value = entries[0].contentRect.height
    }
  })

  watch(element, () => {
    if (element.value) {
      height.value = element.value.clientHeight
    }
  })

  return height
}