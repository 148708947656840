import { reactive, toRef } from "vue";

const state = reactive({
  draggroup: null as string | null,
})

export const useDraggingGroup = () => {
  const draggroup = toRef(state, 'draggroup')

  return {
    draggroup,
  }
}