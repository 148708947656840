
import { defineComponent, PropType, ref, computed, watchEffect, getCurrentInstance } from "vue";
import QButton from "../QButton.vue";
import VueDraggable from "vuedraggable";
import { useDraggingGroup } from "./useDraggingGroup";
import { useVuetify } from '@/composables/useVuetify'
import { useHover } from "../../../../composables/useHover";
import { useElementHeight } from "../../composables";

const __default__ = defineComponent({
	name: "QDraggableListItem",

	components: {
		QButton,
		VueDraggable,
	},

	emits: ["update:item"],

	props: {
		item: {
			type: Object as PropType<any>,
			required: true,
		},
		level: {
			type: Number,
			required: true,
		},
		groupId: {
			type: String,
			required: true,
		},
		depth: {
			type: Number,
			default: 0,
		},
		multiDepth: {
			type: Boolean,
			default: false,
		},

		color: { 
			type: String,
			default: "primary",
		},

		titleKey: { type: String, default: "name" },
		childrenKey: { type: String, default: "children" },
	},

	setup(props, { emit }) {
		const children = ref(props.item[props.childrenKey])
		watchEffect(() => {
			children.value = props.item[props.childrenKey];
		})

		const updateChildren = (v: any) => {
			emit("update:item", {
				...props.item,
				[props.childrenKey]: v,
			}, props.level, props.item);
		}

		const { isHovered, el: hoverEl } = useHover()

		const expanded = ref(children.value?.length > 0);
		const expandedComputed = computed({
			get() {
				if (isHovered.value && isDragging.value) {
					return true
				}

				return expanded.value;
			},

			set(v) {
				expanded.value = v;
			},
		});

		const vm = getCurrentInstance()!
		const computedGroupId = computed(() => {
			if (props.multiDepth) {
				props.groupId
			}

			return 'group-' + (vm as any).proxy._uid
		});

		const { draggroup } = useDraggingGroup()
		const isDragging = computed(
			() => draggroup.value == props.groupId
		);
		const disabled = computed(() => {
			if (props.multiDepth) {
				return false;
			}

			if (!draggroup.value) {
				return false;
			}

			if (draggroup.value == computedGroupId.value) {
				return false;
			}

			return true;
		})

		const onMove = (e: any) => {
			if (props.level > 0) {
				if (e.draggedContext.element.children.length > 0) {
					return false;
				}
			}
		};

		const { getColor } = useVuetify()
		const colorComputed = computed(() => getColor(props.color))

		const footer = ref<HTMLElement | null>(null)
		const footerHeight = useElementHeight(footer)

		return {
			disabled,
			computedGroupId,
			updateChildren,
			isHovered,
			hoverEl,
			colorComputed,
			onMove,
			expandedComputed,
			children,
			draggroup,
			isDragging,
			footer,
			footerHeight,
		};
	},
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "68ae0670": ((_vm.level + 1) * 2 + 'rem'),
  "596e0cac": (_vm.colorComputed),
  "37648dc7": ((_vm.level + 1) * 1 + 'rem')
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__