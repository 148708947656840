var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',_vm._g(_vm._b({staticClass:"q-expand-table",class:{
			'q-expand-table--mobile': _vm.$vuetify.breakpoint.smAndDown,
			'q-expand-table--xs': _vm.$vuetify.breakpoint.xs,
            'q-expand-table--striped': _vm.$props.striped,
		},attrs:{"color":_vm.computedColor,"headers":_vm.computedHeaders,"items":_vm.items,"mobile-breakpoint":0,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"footer-props":{
			..._vm.footerProps,
			itemsPerPageText: _vm.$vuetify.breakpoint.xs ? '' : 'Rows per page',
		}},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.onRowClick},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotScope){return [_vm._t(name,null,null,slotScope)]}}}),{key:`item.q-expand-icon`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-end"},[_vm._t("before-expand",null,null,{ item }),_c('v-icon',[_vm._v("fas fa-chevron-down")]),_vm._t("open-row-button",function(){return [(_vm.doShowOpenRowButton)?_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){return _vm.$emit('click:row', item)}}},[_vm._v("fas fa-external-link-alt")]):_vm._e()]},null,{ item }),_vm._t("after-expand",null,null,{ item })],2)]}},(_vm.doShowExpand)?{key:"expanded-item",fn:function(slotScope){return [_c('td',{staticClass:"q-expand-table__expand-td pa-0",attrs:{"colspan":_vm.computedHeaders.length}},[_c('div',{staticClass:"q-expand-table__expand-background",style:(`background: ${_vm.computedColor};`)}),_vm._t("expand",function(){return [_c('v-simple-table',[_c('tbody',_vm._l((_vm.headersInExpand),function(header){return _c('tr',{key:header[_vm.headerKey]},[_c('td',{staticClass:"text-start"},[_vm._v(" "+_vm._s(header.text)+" ")]),_c('td',{staticClass:"text-end"},[_vm._t(`item.${header.value}`,function(){return [_vm._v(" "+_vm._s(slotScope.item[header.value])+" ")]},null,slotScope)],2)])}),0)]),_vm._t("expanded-item",null,null,slotScope),_vm._t("action",null,null,slotScope)]},null,slotScope)],2)]}}:null],null,true)},'v-data-table',_vm.$attrs,false),_vm.computedListeners))
}
var staticRenderFns = []

export { render, staticRenderFns }