import { ref, watchEffect } from 'vue';

export const useHover = () => {
  const isHovered = ref(false)
  const el = ref()

  watchEffect(() => {
    if (!el.value) return

    const $el = '$el' in el.value ? el.value.$el : el.value

    $el.addEventListener('mouseenter', () => {
      isHovered.value = true
    })
    $el.addEventListener('mouseleave', () => {
      isHovered.value = false
    })
  })

  return {
    isHovered,
    el,
  }
}