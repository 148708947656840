import { Ref, watch } from 'vue'

export const useThrottleWatch = <T extends Ref>(value: T, callback: (newVal: T, oldVal: T) => void, delay = 16) => {
  let timer: any = null
  watch(value, (newValue, oldVal) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      callback(newValue, oldVal)
    }, delay)
  })
}
